<template>
    <ChangeCatalityc/>
</template>

<script>
import ChangeCatalityc from '@/components/app/ChangeCatalityc'
export default {
    components:{
        ChangeCatalityc
    }
}
</script>